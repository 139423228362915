import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import {
    useFormFieldsOptionsQuery,
    useGetAllIssueStatusQuery,
    useGetAllServiceCenterQuery,
} from '../query'
import { useTranslation } from 'react-i18next'

const IssueTableFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    status,
    setStatus,
    state,
    setState,
    serviceCenter,
    setServiceCenter,
    rangeDate,
    setRangeDate,
    showRangePicker = true,
    showServiceCenter = true,
}) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.issues.overview',
    })
    // HANDLE STATE
    const { data: stateData, isSuccess: isStateLoadingSuccess } =
        useFormFieldsOptionsQuery()
    const states = useMemo(() => {
        if (isStateLoadingSuccess) {
            const result = stateData?.available_states?.map((item) => ({
                id: item,
                name: item,
            }))
            result.unshift({ id: '', name: t('all', { keyPrefix: 'general' }) })
            return result
        }
        return []
    }, [isStateLoadingSuccess, stateData?.available_states, t])

    // HANDLE STATUS
    const { data: statusData, isSuccess: isStatusLoadingSuccess } =
        useGetAllIssueStatusQuery()
    const issueStatus = useMemo(() => {
        if (isStatusLoadingSuccess) {
            const result = statusData?.data?.map((item) => ({
                id: item.status,
                name: item.status,
            }))
            result.unshift({ id: '', name: t('all', { keyPrefix: 'general' }) })
            return result
        }
        return []
    }, [isStatusLoadingSuccess, statusData?.data, t])

    // HANDLE SERVICE CENTER
    const { data: serviceCenterData, isSuccess: isServiceLoadingSuccess } =
        useGetAllServiceCenterQuery()
    const serviceCenters = useMemo(() => {
        if (isServiceLoadingSuccess) {
            const result = serviceCenterData?.data?.map((item) => ({
                id: item?.service_center_id,
                name: item?.name,
            }))
            result.unshift({ id: '', name: t('all', { keyPrefix: 'general' }) })
            return result
        }
        return []
    }, [isServiceLoadingSuccess, serviceCenterData?.data, t])

    const selectGroup = useMemo(() => {
        return showServiceCenter
            ? [
                  {
                      label: t('state'),
                      handleFunc: setState,
                      dataSet: states,
                      value: state,
                  },
                  {
                      label: t('status'),
                      handleFunc: setStatus,
                      dataSet: issueStatus,
                      value: status,
                  },
                  {
                      label: t('serviceCenter'),
                      handleFunc: setServiceCenter,
                      dataSet: serviceCenters,
                      value: serviceCenter,
                  },
              ]
            : [
                  {
                      label: t('state'),
                      handleFunc: setState,
                      dataSet: states,
                      value: state,
                  },
                  {
                      label: t('status'),
                      handleFunc: setStatus,
                      dataSet: issueStatus,
                      value: status,
                  },
              ]
    }, [
        showServiceCenter,
        t,
        setState,
        states,
        state,
        setStatus,
        issueStatus,
        status,
        setServiceCenter,
        serviceCenters,
        serviceCenter,
    ])

    const rangeDateGroup = useMemo(() => {
        return showRangePicker
            ? [
                  {
                      label: t('rangeDate'),
                      handleFunc: setRangeDate,
                      value: rangeDate,
                  },
              ]
            : []
    }, [rangeDate, setRangeDate, showRangePicker, t])

    return (
        <TableFilters
            {...{
                selectGroup,
                search,
                searchHandler,
                onEnterSearch,
                rangeDateGroup,
            }}
            showDatePicker={true}
            searchPlaceHolder='issue'
            page='issue'
        />
    )
}

IssueTableFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    serviceCenter: propTypes.string,
    setServiceCenter: propTypes.func,
    onEnterSearch: propTypes.func,
    status: propTypes.string,
    setStatus: propTypes.func,
    state: propTypes.string,
    setState: propTypes.func,
    rangeDate: propTypes.any,
    setRangeDate: propTypes.func,
    showRangePicker: propTypes.bool,
    showServiceCenter: propTypes.bool,
}

export default IssueTableFilter
