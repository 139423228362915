import { useState } from 'react'
import { Box, IconButton, Paper } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import PropTypes from 'prop-types'
import StyledModal from '~/components/StyledModal'
import SectionTitle from '~/pages/Diagnostic/components/SectionTitle'
import PartToChangeTable from '~/pages/Issue/Repair/Component/PartToChangeTable'
import { useTranslation } from 'react-i18next'

const PartsToChangePopup = ({ issue, open, onClose }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.issues.repair',
    })
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 800,
                gap: 2,
            }}
        >
            <Box sx={{ height: '100%' }}>
                <Paper sx={{ height: '100%', px: 2, py: 1 }} variant='outlined'>
                    <SectionTitle title={t('partsToChange')} />
                    <PartToChangeTable
                        issueId={issue.id}
                        finished={true}
                        getAll={true}
                    />
                </Paper>
            </Box>
        </StyledModal>
    )
}

PartsToChangePopup.propTypes = {
    issue: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const DeviceHistoryIssue = ({ item }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            {item.id}
            <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => {
                    setOpen(true)
                }}
            >
                <KeyboardArrowRight fontSize='small' />
            </IconButton>
            {open && (
                <PartsToChangePopup
                    issue={item}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    )
}

DeviceHistoryIssue.propTypes = {
    item: PropTypes.object,
}

export default DeviceHistoryIssue
