import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import PageLayout from '~/components/layout/PageLayout'
import { ISSUE_DISPATCH, ISSUE_ISSUE } from '~/constants/Routes'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectDevice } from '~/store/device/selector'
import { selectSelectedPartCodes } from '~/store/partcode/selector'
import {
    clearSelectedPartCodeTypes,
    clearSelectedPartCodes,
    clearShowedPartCode,
    pushShowPartCodes,
} from '~/store/partcode/slice'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { setDispatchSerial } from '~/store/dispatch/slice'
import Button from './components/Button'
import CauseSection from './components/CauseSection'
import DescriptionSection from './components/DescriptionSection'
import DispatchInfoSection from './components/DispatchInfoSection'
import PartCodeSection from './components/PartCodeSection'
import SerialSearchSection from './components/SerialSearchSection'
import ServiceCenterSection from './components/ServiceCenterSection'
import { LOCALIZATION } from './constants'
import { useCreateDispatchMutation, useUploadPictureDispatch } from './mutate'
import AttachmentInfoSection from './components/AttachmentInfoSection'
import DispatchWarningPopup from '../component/DispatchWarningPopup'
import PropTypes from 'prop-types'
import { parseApiErrorMessage, textValidateRegex } from '~/utils/helpers'

const breadcrumbs = [
    { route: ISSUE_ISSUE, title: 'Issues' },
    {
        route: '#',
        title: 'Dispatches',
    },
    {
        route: '#',
        title: 'add',
    },
]

const AddDispatch = () => {
    const refDescriptionSection = useRef(null)
    const refServiceCenterSection = useRef(null)
    const refPartCodeSection = useRef(null)
    const { t } = useTranslation()
    const [step, setStep] = useState(1)
    const [searchedSerial, setSearchedSerial] = useState('')
    const { mutate, isLoading } = useCreateDispatchMutation()
    const { mutate: pictureMutate, isLoading: isUploading } =
        useUploadPictureDispatch()

    const dispatch = useDispatch()
    const device = useSelector(selectDevice)
    const [isFoundSerialData, setIsFoundSerialData] = useState(false)
    const [returnToDepot, setReturnToDepot] = useState(false)
    const selectedPartCodes = useSelector(selectSelectedPartCodes)
    const [clickedCause, setClickedCause] = useState({})
    const { openSnackbar } = useDialogContext()
    const navigate = useNavigate()
    const [selectedCauses, setSelectedCauses] = useState([])
    const [ticketId, setTicketId] = useState(null)
    const [selectedAttachments, setSelectedAttachments] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const [dispatches, setDispatches] = useState([])
    const [initComp, setInitComp] = useState(true)
    const ppidRegex = device?.vendor?.ppid_validation_regex ?? null

    const location = useLocation()
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('serial')) {
            const serial = searchParams.get('serial')
            setSearchedSerial(serial)
            dispatch(setDispatchSerial(serial))
            dispatch(clearSelectedPartCodes())
            dispatch(clearSelectedPartCodeTypes())
            dispatch(clearShowedPartCode())
        }
    }, [dispatch, location.search])

    const freshSelectedPartCodes = useCallback(() => {
        dispatch(clearSelectedPartCodes())
        dispatch(clearSelectedPartCodeTypes())
        dispatch(pushShowPartCodes())
    }, [dispatch])

    const onSubmit = useCallback(
        (values) => {
            setInitComp(false)
            const missRequiredPPID = selectedPartCodes.some(
                (partCode) => partCode.serializable && partCode.ppid === '',
            )
            const maxAttempPPIDLength = selectedPartCodes.some(
                (partCode) =>
                    partCode.ppid !== '' &&
                    ppidRegex &&
                    !textValidateRegex(ppidRegex, partCode.ppid),
            )

            if (missRequiredPPID || maxAttempPPIDLength) {
                refPartCodeSection.current?.scrollIntoView({
                    behavior: 'smooth',
                })
                return false
            }

            const booleanKeys = [
                'request_complete_care',
                'request_on_site_tech',
                'request_return_to_depot',
            ]
            booleanKeys.forEach((key) => {
                values[key] =
                    values[key] === true || parseInt(values[key]) === 1
            })

            if (step === 3) {
                if (selectedFiles && selectedFiles.length > 0) {
                    const uploadPictures = selectedFiles.map((files) => {
                        return {
                            ...files,
                            image: files.notConvert ? files.src : files.image,
                            notConvert: files.notConvert || false,
                        }
                    })
                    pictureMutate(
                        {
                            id: values.ticket_id,
                            payload: uploadPictures,
                        },
                        {
                            onSuccess: ({
                                reach_out_limit,
                                reach_out_message,
                            }) => {
                                if (reach_out_limit) {
                                    openSnackbar({
                                        message: reach_out_message,
                                        type: 'warning',
                                        duration: 8000,
                                    })
                                }
                                mutate(
                                    {
                                        ...values,
                                        device: device?.id,
                                        parts: selectedPartCodes,
                                        attachments: selectedAttachments,
                                    },
                                    {
                                        onSuccess: () => {
                                            openSnackbar({
                                                message: 'Create successful!',
                                                type: 'success',
                                            })
                                            freshSelectedPartCodes()
                                            navigate(ISSUE_DISPATCH)
                                            window.scrollTo(0, 0)
                                        },
                                        onError: (data) => {
                                            let messageError =
                                                parseApiErrorMessage(data)
                                            const rData = data?.response?.data
                                            if (
                                                rData?.DELL_error &&
                                                rData?.notes
                                            ) {
                                                messageError += ` ${rData?.notes}`
                                            }
                                            openSnackbar({
                                                message: messageError,
                                                type: 'error',
                                            })
                                        },
                                    },
                                )
                            },
                        },
                    )
                } else {
                    mutate(
                        {
                            ...values,
                            device: device?.id,
                            parts: selectedPartCodes,
                            attachments: selectedAttachments,
                        },
                        {
                            onSuccess: (data) => {
                                openSnackbar({
                                    message: 'Create successful!',
                                    type: 'success',
                                })
                                freshSelectedPartCodes()
                                navigate(ISSUE_DISPATCH)
                                window.scrollTo(0, 0)
                            },
                            onError: (data) => {
                                let messageError = parseApiErrorMessage(data)
                                const rData = data?.response?.data
                                if (rData?.DELL_error && rData?.notes) {
                                    messageError += ` ${rData?.notes}`
                                }
                                openSnackbar({
                                    message: messageError,
                                    type: 'error',
                                })
                                if (data.response.status === 409) {
                                    setOpenPopup(true)
                                    setDispatches(rData)
                                }
                            },
                        },
                    )
                }
            }
        },
        [
            selectedPartCodes,
            step,
            ppidRegex,
            selectedFiles,
            pictureMutate,
            mutate,
            device?.id,
            selectedAttachments,
            openSnackbar,
            freshSelectedPartCodes,
            navigate,
        ],
    )

    return (
        <>
            <PageLayout
                {...{ breadcrumbs }}
                pageName={t(`${LOCALIZATION}.pageName`)}
            >
                <Form
                    initialValues={{
                        request_complete_care: false,
                        request_return_to_depot: false,
                        request_on_site_tech: false,
                    }}
                    onSubmit={onSubmit}
                    render={({
                        handleSubmit,
                        values,
                        errors,
                        ...formProps
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <SerialSearchSection
                                    show={step >= 1}
                                    {...{
                                        searchedSerial,
                                        setSearchedSerial,
                                        setStep,
                                        step,
                                        setIsFoundSerialData,
                                    }}
                                />
                                <ServiceCenterSection
                                    show={step >= 2 && isFoundSerialData}
                                    setTicketId={setTicketId}
                                    innerRef={refServiceCenterSection}
                                />
                                <DispatchInfoSection
                                    show={step >= 2 && isFoundSerialData}
                                    {...{
                                        values,
                                        setReturnToDepot,
                                    }}
                                />
                                <CauseSection
                                    show={
                                        step >= 3 &&
                                        isFoundSerialData &&
                                        !returnToDepot
                                    }
                                    setSelectedCauses={setSelectedCauses}
                                    setClickedCause={setClickedCause}
                                    serial={searchedSerial}
                                />
                                <DescriptionSection
                                    show={step >= 3 && isFoundSerialData}
                                    selectedCauses={selectedCauses}
                                    innerRef={refDescriptionSection}
                                />
                                <PartCodeSection
                                    show={
                                        step >= 3 &&
                                        isFoundSerialData &&
                                        !returnToDepot
                                    }
                                    clickedCause={clickedCause}
                                    serial={searchedSerial}
                                    initComp={initComp}
                                    setInitComp={setInitComp}
                                    innerRef={refPartCodeSection}
                                />
                                {isFoundSerialData && step >= 3 && (
                                    <AttachmentInfoSection
                                        ticketId={ticketId}
                                        selected={selectedAttachments}
                                        setSelected={setSelectedAttachments}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                    />
                                )}
                                {isFoundSerialData && (
                                    <NextButton
                                        isLoading={isLoading || isUploading}
                                        setStep={setStep}
                                        step={step}
                                        errors={errors}
                                        refs={{
                                            troubleshooting_note:
                                                refDescriptionSection,
                                            service_center:
                                                refServiceCenterSection,
                                        }}
                                    />
                                )}
                            </Stack>
                        </form>
                    )}
                />
            </PageLayout>
            <DispatchWarningPopup
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                dispatches={dispatches}
            />
        </>
    )
}

const NextButton = ({ isLoading, setStep, step, errors, refs }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'button' })
    if (step === 1) return <></>
    if (step < 3) {
        return (
            <Button type='button' onClick={() => setStep(step + 1)}>
                {t('next')}
            </Button>
        )
    }
    return (
        <LoadingButton
            type='submit'
            loading={isLoading}
            variant='contained'
            size='small'
            sx={{
                textTransform: 'none',
                backgroundColor: '#76B72A',
            }}
            onClick={() => {
                const keys = Object.keys(errors)
                if (keys.length > 0) {
                    const keysToCheck = new Set([
                        'service_center',
                        'ticket_id',
                        'esd_location',
                        'description_of_problem',
                    ])

                    if (keys.some((key) => keysToCheck.has(key))) {
                        refs.service_center?.current?.scrollIntoView({
                            behavior: 'smooth',
                        })
                    } else if (keys.includes('troubleshooting_note')) {
                        refs.troubleshooting_note?.current?.scrollIntoView({
                            behavior: 'smooth',
                        })
                    }
                }
            }}
        >
            {t('save')}
        </LoadingButton>
    )
}

NextButton.propTypes = {
    isLoading: PropTypes.bool,
    setStep: PropTypes.func,
    step: PropTypes.number,
    errors: PropTypes.object,
    values: PropTypes.object,
    refs: PropTypes.object,
}

export default AddDispatch
