import { Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LOCALIZATION } from '../constants'
import Layout from './Section/Layout'
import ImageList from '~/components/Image/ImageList'
import { usePictureDispatchQuery } from '~/pages/Ticket/query'
import PictureCapturePopup from '~/components/PictureCapturePopup'
import { LoadingButton } from '~/components/Button'
import PropTypes from 'prop-types'
import UploadDispatchPicturePopup from './UploadDispatchPicturePopup'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import { useDialogContext } from '~/components/providers/StyledDialogContext'

const MAX_DISPATCHING_IMAGES = 8

const AttachmentInfoSection = ({
    ticketId,
    selected,
    setSelected,
    selectedFiles,
    setSelectedFiles,
}) => {
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const userPermissions = useSelector(selectUserPermissions)
    const { data, isLoading } = usePictureDispatchQuery(ticketId)
    const [open, setOpen] = useState(false)
    const [openUpload, setOpenUpload] = useState(false)
    const isHavingUploadPermission =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.dispatches.upload_pictures')
    const isHavingTakePicturePermission =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.dispatches.take_pictures')

    useEffect(() => {
        setSelected((v) => [])
    }, [setSelected, ticketId])

    const currentImageList = useMemo(() => {
        return (
            data?.data.map((v) => ({
                src: v.preview_url,
                id: v.id,
                checked: selected && selected.includes(v.id),
                is_current: true,
            })) || []
        )
    }, [data?.data, selected])

    const imageLists = useMemo(() => {
        const list = selectedFiles
            ? selectedFiles.map((file, key) => ({
                  src: file.image,
                  id: key,
                  checked: file.checked,
                  removeable: true,
              }))
            : []
        return [...currentImageList, ...list]
    }, [currentImageList, selectedFiles])

    const numberDispatchingImages = imageLists?.filter(
        (image) => image?.checked,
    ).length

    const handleChecked = useCallback(
        (checked, image) => {
            if (checked && numberDispatchingImages >= MAX_DISPATCHING_IMAGES) {
                openSnackbar({
                    message: t(
                        'pages.diagnostic.reached_limit_dispatch_images',
                        {
                            limitation: MAX_DISPATCHING_IMAGES,
                        },
                    ),
                    type: 'warning',
                    duration: 8000,
                })
                return
            }
            if (image.is_current === true) {
                setSelected((v) => {
                    let currentSelect = v
                    if (checked) {
                        currentSelect = [...currentSelect, image.id]
                    } else {
                        currentSelect = currentSelect.filter(
                            (item) => item !== image.id,
                        )
                    }
                    return currentSelect
                })
            } else {
                setSelectedFiles((files) => {
                    return files.map((file, key) => {
                        if (key === image.id) {
                            file.checked = checked
                        }
                        return file
                    })
                })
            }
        },
        [
            numberDispatchingImages,
            openSnackbar,
            setSelected,
            setSelectedFiles,
            t,
        ],
    )

    if (isLoading) {
        return <></>
    }

    return (
        <Layout>
            <Grid container spacing={2}>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    lg={12}
                    justifyContent='space-between'
                >
                    <Grid item>
                        <Typography variant='h6' gutterBottom>
                            {t(`${LOCALIZATION}.attachments`)}
                        </Typography>
                    </Grid>

                    <Grid item>
                        {isHavingTakePicturePermission && (
                            <LoadingButton
                                label={t(`${LOCALIZATION}.addPictures`)}
                                onClick={() => {
                                    setOpen(true)
                                }}
                            />
                        )}

                        {isHavingUploadPermission && (
                            <LoadingButton
                                label={t(`${LOCALIZATION}.uploadPictures`)}
                                onClick={() => {
                                    setOpenUpload(true)
                                }}
                                style={{ marginLeft: '10px' }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} align='center'>
                    {imageLists.length > 0 && (
                        <ImageList
                            images={imageLists}
                            imageListProps={{
                                cols: 10,
                            }}
                            showCheckbox={true}
                            showRemoveImage={false}
                            handleRemoveImage={() => {}}
                            handleChecked={(checked, image) =>
                                handleChecked(checked, image)
                            }
                        />
                    )}
                </Grid>
            </Grid>
            <PictureCapturePopup
                {...{
                    open,
                    selectedFiles,
                    setSelectedFiles,
                    isLoading: false,
                    onSubmit: () => {
                        setOpen(false)
                    },
                    onClose: () => {
                        setOpen(false)
                    },
                    imageLimit: MAX_DISPATCHING_IMAGES,
                    btnLabel: 'button.next',
                    currentImageList,
                    showRemoveCurrentImage: false,
                }}
            />

            <UploadDispatchPicturePopup
                open={openUpload}
                setOpenUploadPicture={setOpenUpload}
                setSelectedFiles={setSelectedFiles}
            />
        </Layout>
    )
}

AttachmentInfoSection.propTypes = {
    ticketId: PropTypes.number,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    selectedFiles: PropTypes.array,
    setSelectedFiles: PropTypes.func,
}

export default AttachmentInfoSection
