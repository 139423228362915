import {
    Box,
    Checkbox,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    useDoaPicturesQuery,
    useIssuePartCodesQuery,
    useIssueQuery,
} from '../query'
import { LoadingButton } from '~/components/Button'
import { useRemoveDoaPictureMutate, useSaveRepairMutation } from '../mutation'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { DASHBOARD, ISSUE_ISSUE } from '~/constants/Routes'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { PPIDPattern } from './constants'
import {
    DoaPopup,
    RepairCannotBeFinishedPopup,
} from '~/pages/Issue/Repair/Component/Popups'
import { useTranslation } from 'react-i18next'
import {
    checkValidPartNumber,
    parseErrorMessageAndShow,
    textValidateRegex,
} from '~/utils/helpers'
import PictureCapturePopup from '~/components/PictureCapturePopup'
import {
    useDispatchPictureMutation,
    usePictureMutation,
} from '~/pages/Diagnostic/mutation'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'

const LOCALIZATION = 'pages.issues.repair.'

const RemovedPartTable = ({ issueId, setOpenRemovePart, techComment }) => {
    const { t } = useTranslation()
    const clientQuery = useQueryClient()
    const { openSnackbar } = useDialogContext()

    const { data: partCodeData, isSuccess } = useIssuePartCodesQuery(
        issueId,
        false,
    )
    const { data: issue, isSuccess: isGetIssueSuccess } = useIssueQuery(issueId)
    const ppidRegex = issue?.data?.ticket?.device?.vendor?.ppid_validation_regex
    const [partCodes, setPartCodes] = useState([])
    const [comment, setComment] = useState(techComment)
    const [commentError, setCommentError] = useState('')
    const [
        openRepairCannotBeFinishedPopup,
        setOpenRepairCannotBeFinishedPopup,
    ] = useState(false)
    const [openDoaPopup, setOpenDoaPopup] = useState(false)
    const [doaPartCodes, setDoaPartCodes] = useState([])
    const [serial, setSerial] = useState('')
    const [selectedCauses, setSelectedCauses] = useState({})
    const [selectedFiles, setSelectedFiles] = useState([])
    const [isPicturePopupOpen, setIsPicturePopupOpen] = useState(false)
    const [removePictureId, setRemovePictureId] = useState('')
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [minimumNumberOfPhotosRequired, setMinimumNumberOfPhotosRequired] =
        useState(0)
    const [hasDoaPart, setHasDoaPart] = useState(false)

    const { mutate: saveRepair, isLoading } = useSaveRepairMutation()
    const { mutate: pictureMutate, isLoading: isPictureLoading } =
        usePictureMutation(true)
    const { mutate: dispatchPictureMutate, isLoading: isUpdating } =
        useDispatchPictureMutation(issueId)
    const { mutate: removePictureMutate, isLoading: isRemoving } =
        useRemoveDoaPictureMutate(issueId)
    const { data: pictures, refetch } = useDoaPicturesQuery(issueId, hasDoaPart)

    const onClosePicturePopup = () => {
        setSelectedFiles([])
        setIsPicturePopupOpen(false)
    }

    const handleRemoveImage = (item) => {
        setRemovePictureId(item.id)
        setOpenDeleteConfirm(true)
    }

    const handleConfirmRemoveImage = (key) =>
        removePictureMutate(key, {
            onSettled: () => {
                refetch()
                setOpenDeleteConfirm(false)
            },
        })

    const handleCheckedDispatchPictures = useCallback(
        (checked, image) => {
            if (isUpdating) {
                return
            }

            if (!image.is_current) {
                setSelectedFiles((files) => {
                    return files.map((file, key) => {
                        if (key === image.id) {
                            file.checked = checked
                        }
                        return file
                    })
                })
                return
            }

            dispatchPictureMutate([
                {
                    media_id: image.id,
                    dispatch_enabled: checked,
                },
            ])
        },
        [dispatchPictureMutate, isUpdating],
    )

    const currentImageList = useMemo(() => {
        return (
            pictures?.data &&
            pictures.data.map((image) => {
                return {
                    src: image.preview_url,
                    id: image.id,
                    is_current: true,
                    checked: image.is_dispatch_image,
                    removeable: image.removeable,
                }
            })
        )
    }, [pictures])

    const handleClose = () => {
        setOpenDeleteConfirm(false)
    }

    const onSubmit = useCallback(() => {
        const newSelectedImages = selectedFiles.filter(
            (file) => file.checked === true,
        )
        const existingSelectedImages = currentImageList.filter(
            (file) => file.checked === true,
        )
        const allSelectedImages = [
            ...newSelectedImages,
            ...existingSelectedImages,
        ]
        if (allSelectedImages.length >= minimumNumberOfPhotosRequired) {
            if (newSelectedImages.length === 0) {
                setIsPicturePopupOpen(false)
                setOpenDoaPopup(true)
                return
            }
            pictureMutate(selectedFiles, {
                onError: (error) =>
                    parseErrorMessageAndShow(error, openSnackbar),
                onSuccess: () => {
                    refetch()
                    setIsPicturePopupOpen(false)
                    setSelectedFiles([])
                    setOpenDoaPopup(true)
                },
            })
        }
    }, [
        currentImageList,
        minimumNumberOfPhotosRequired,
        openSnackbar,
        pictureMutate,
        refetch,
        selectedFiles,
    ])

    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            const part = _.cloneDeep(partCodeData).map((p) => {
                return { ...p, replaced: true }
            })
            setPartCodes(part)
        }
    }, [isSuccess, partCodeData])

    useEffect(() => {
        if (isGetIssueSuccess) {
            setSerial(issue?.data?.name)
        }
    }, [issue, isGetIssueSuccess])

    const arePartCodesValid = () => {
        const validatePpid = partCodes.some((partCode) => {
            const isNotEmpty = !_.isEmpty(partCode.old_ppid)
            const isInvalid = !textValidateRegex(ppidRegex, partCode.old_ppid)
            return isNotEmpty && isInvalid
        })

        if (validatePpid) {
            openSnackbar({
                message: t('message.ppidAttemptedCharacter'),
                type: 'error',
            })
            return false
        }

        const validatePartNumber = partCodes?.some(
            (item) =>
                item.replaced && !checkValidPartNumber(item.old_part_number),
        )

        if (validatePartNumber) {
            openSnackbar({
                message: t('message.partNumberIsNotValid'),
                type: 'error',
            })

            return false
        }

        return true
    }

    const onRepairCannotBeFinishedClick = () => {
        const hasError = partCodes.some((partCode) => {
            if (partCode?.replaced === true) {
                if (
                    partCode?.old_part_number === null ||
                    partCode?.old_part_number.length === 0
                ) {
                    return true
                }

                if (
                    partCode?.doa === true &&
                    (partCode?.old_ppid === null ||
                        partCode?.old_ppid.length === 0)
                ) {
                    return true
                }
            }
            return false
        })

        if (hasError) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.requiredAllFields`),
                type: 'error',
            })

            return
        }
        const doaPartCodes = partCodes.map((partCode) =>
            partCode?.doa === true ? partCode.code : '',
        )

        setDoaPartCodes(doaPartCodes.filter((partCode) => partCode.length > 0))

        if (!arePartCodesValid()) {
            return
        }

        const doaParts = partCodes.filter((partCode) => partCode.doa === true)
        if (doaParts.length === 0) {
            setOpenRepairCannotBeFinishedPopup(true)
        } else {
            setHasDoaPart(true)
            setIsPicturePopupOpen(true)
            setMinimumNumberOfPhotosRequired(doaParts.length)
        }
    }

    const onRepairCannotBeFinishedSaveCommentClick = () => {
        if (_.isEmpty(comment) || comment.trim() === '') {
            openSnackbar({
                message: t(`${LOCALIZATION}message.requiredComment`),
                type: 'error',
            })
            setCommentError(t(`${LOCALIZATION}message.requiredComment`))
            return
        }

        if (!arePartCodesValid()) {
            return
        }

        const causes =
            Object.keys(selectedCauses).length > 0
                ? Object.keys(selectedCauses)
                : null

        saveRepair(
            {
                issueId,
                payload: {
                    part_codes: partCodes,
                    repaired: false,
                    comment,
                    causes,
                },
            },
            {
                onSuccess: () => {
                    clientQuery.invalidateQueries(['issue', issueId])
                    clientQuery.invalidateQueries(['part_issue', issueId])
                    clientQuery.invalidateQueries(['issue_logs'])
                    if (partCodes.some((item) => item?.doa === true)) {
                        openSnackbar({
                            message: t(
                                `${LOCALIZATION}message.createdDoaIssueSuccessfully`,
                            ),
                            type: 'success',
                        })
                    }
                    navigate({ pathname: ISSUE_ISSUE })
                },
                onError: (error) =>
                    parseErrorMessageAndShow(error, openSnackbar),
            },
        )
    }

    const onDeviceRepairedClick = () => {
        const hasError = partCodes.some((partCode) => {
            const checkReplace = partCode.replaced
            if (!checkReplace) {
                return false
            }

            const checkPartNumber =
                !!partCode.old_part_number && partCode.old_part_number !== ''

            const checkReturnableAndSerializable =
                partCode.returnable || partCode.serializable

            const checkPPID =
                (checkReturnableAndSerializable &&
                    !!partCode.old_ppid &&
                    partCode.old_ppid !== '') ||
                !checkReturnableAndSerializable

            return !checkPartNumber || !checkPPID
        })

        if (hasError) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.requiredAllFields`),
                type: 'error',
            })

            return
        }

        const samePPID = partCodes.some((partCode) => {
            return partCode.same_ppid_text?.length
        })

        if (samePPID) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.duplicatedPpid`),
                type: 'error',
            })

            return
        }

        if (!arePartCodesValid()) {
            return
        }

        saveRepair(
            {
                issueId,
                payload: { part_codes: partCodes, repaired: true },
            },
            {
                onSuccess: () => {
                    setOpenRemovePart(false)
                    clientQuery.invalidateQueries(['issue', issueId])
                    clientQuery.invalidateQueries(['part_issue', issueId])
                    clientQuery.invalidateQueries(['issue_logs'])
                    navigate({ pathname: DASHBOARD })
                },
                onError: (error) =>
                    parseErrorMessageAndShow(error, openSnackbar),
            },
        )
    }

    const samePpidOnChange = useCallback(
        (e, index) => {
            const newPPID = e.target.value

            const match = newPPID.match(PPIDPattern)

            setPartCodes((prev) => {
                const newArr = [...prev]

                newArr[index].old_ppid = newPPID
                newArr[index].old_part_number = match
                    ? match[1]
                    : newArr[index].old_part_number

                newArr[index].same_ppid_text = undefined
                if (
                    newArr[index].old_ppid.toLowerCase() ===
                    newArr[index].new_ppid?.toLowerCase()
                ) {
                    newArr[index].same_ppid_text = t(
                        `${LOCALIZATION}message.samePpid`,
                    )
                }

                return newArr
            })
            if (!textValidateRegex(ppidRegex, newPPID)) {
                openSnackbar({
                    message: t('message.ppidAttemptedCharacter'),
                    type: 'error',
                })
            }
        },
        [openSnackbar, ppidRegex, t],
    )

    const handleDoaClicked = useCallback((e, index) => {
        setPartCodes((prev) => {
            const newArr = [...prev]
            newArr[index].doa = e.target.checked

            return newArr
        })
    }, [])

    const hasDoaChecked = useMemo(
        () => partCodes.some((part) => part.doa),
        [partCodes],
    )

    useEffect(() => {
        const doaText = partCodes.reduce((text, part) => {
            if (part.doa) {
                return text + `${part.code}:` + '\n'
            }

            return text
        }, '\n')
        setComment(techComment + doaText)
    }, [partCodes, techComment])

    return (
        <>
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.receivedPartNumber`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.partCode`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.partName`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.removedPartName`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.removedPpid`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.notReplaced`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(`${LOCALIZATION}removePartTable.doa`)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partCodes.map((part, index) => {
                                const {
                                    new_part_number,
                                    code,
                                    name,
                                    serializable,
                                    returnable,
                                    doa,
                                    replaced,
                                    old_part_number,
                                    old_ppid,
                                    same_ppid_text,
                                } = part

                                const oldPpidExists = old_ppid?.length
                                const hasSamePpidText =
                                    typeof same_ppid_text !== 'undefined'
                                const samePpidTextLength =
                                    same_ppid_text?.length

                                const ppidValid =
                                    ppidRegex &&
                                    !_.isEmpty(old_ppid) &&
                                    !textValidateRegex(ppidRegex, old_ppid)

                                return (
                                    <TableRow key={index}>
                                        <TableCell>{new_part_number}</TableCell>
                                        <TableCell>{code}</TableCell>
                                        <TableCell>{name}</TableCell>
                                        <TableCell>
                                            <Stack>
                                                <TextField
                                                    autoFocus={index === 0}
                                                    disabled={!replaced}
                                                    error={
                                                        !old_part_number?.length
                                                    }
                                                    key={index}
                                                    variant='outlined'
                                                    value={old_part_number}
                                                    onChange={(e) => {
                                                        setPartCodes((prev) => {
                                                            const newArr = [
                                                                ...prev,
                                                            ]

                                                            newArr[
                                                                index
                                                            ].old_part_number =
                                                                e.target.value
                                                                    .split(' ')
                                                                    .join('')

                                                            return newArr
                                                        })
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input.Mui-disabled':
                                                            {
                                                                backgroundColor:
                                                                    '#d3d3d3',
                                                            },
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.error
                                                                .light,
                                                        height: 10,
                                                    }}
                                                >
                                                    {!old_part_number?.length &&
                                                        replaced && (
                                                            <>
                                                                {t(
                                                                    'validation.required',
                                                                )}
                                                            </>
                                                        )}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack>
                                                <TextField
                                                    disabled={!replaced}
                                                    error={
                                                        ((serializable ||
                                                            returnable ||
                                                            doa) &&
                                                            (!oldPpidExists ||
                                                                (hasSamePpidText &&
                                                                    samePpidTextLength))) ||
                                                        ppidValid
                                                    }
                                                    helperText={same_ppid_text}
                                                    key={index}
                                                    variant='outlined'
                                                    value={old_ppid}
                                                    onChange={(e) =>
                                                        samePpidOnChange(
                                                            e,
                                                            index,
                                                        )
                                                    }
                                                    sx={{
                                                        '& .MuiInputBase-input.Mui-disabled':
                                                            {
                                                                backgroundColor:
                                                                    '#d3d3d3',
                                                            },
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.error
                                                                .light,
                                                        height: 10,
                                                    }}
                                                >
                                                    {(serializable ||
                                                        returnable ||
                                                        doa) &&
                                                        (!old_ppid?.length ||
                                                            (hasSamePpidText &&
                                                                samePpidTextLength)) &&
                                                        replaced && (
                                                            <>
                                                                {t(
                                                                    'validation.required',
                                                                )}
                                                            </>
                                                        )}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                onClick={(e) => {
                                                    setPartCodes((prev) => {
                                                        const newArr = [...prev]

                                                        newArr[index].replaced =
                                                            !e.target.checked

                                                        return newArr
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                onClick={(e) =>
                                                    handleDoaClicked(e, index)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.cancel`)}
                        onClick={() => {
                            setOpenRemovePart(false)
                        }}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.cannotFinish`)}
                        onClick={onRepairCannotBeFinishedClick}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.repair`)}
                        onClick={onDeviceRepairedClick}
                        loading={isLoading}
                        disabled={hasDoaChecked}
                    />
                </Box>
            </Box>

            <RepairCannotBeFinishedPopup
                closeHandler={() => setOpenRepairCannotBeFinishedPopup(false)}
                isOpen={openRepairCannotBeFinishedPopup}
                {...{
                    comment,
                    isLoading,
                    setComment,
                    setCommentError,
                    commentError,
                    onRepairCannotBeFinishedSaveCommentClick,
                }}
            />

            <DoaPopup
                closeHandler={() => setOpenDoaPopup(false)}
                isLoadingSubmit={isLoading}
                isOpen={openDoaPopup}
                partCodes={doaPartCodes}
                {...{
                    serial,
                    selectedCauses,
                    setSelectedCauses,
                    onRepairCannotBeFinishedSaveCommentClick,
                }}
            />

            <PictureCapturePopup
                {...{
                    open: isPicturePopupOpen,
                    selectedFiles,
                    setSelectedFiles,
                    isLoading: isPictureLoading,
                    isAddingOrRemovingDispatchPhoto: isUpdating,
                    onSubmit,
                    onClose: onClosePicturePopup,
                    imageLimit: pictures?.pictures_limit,
                    btnLabel: 'button.next',
                    currentImageList,
                    handleRemoveCurrent: handleRemoveImage,
                    handleChecked: handleCheckedDispatchPictures,
                    showCheckbox: true,
                    defaultAddToDispatch: true,
                    minimumNumberOfPhotosRequired,
                    strictForDispatch: true,
                }}
            />

            <DeleteConfirmPopup
                {...{ openDeleteConfirm, handleClose }}
                isLoading={isRemoving}
                handleDelete={() => handleConfirmRemoveImage(removePictureId)}
                informationDialog={{
                    title: `${t('dialog.deletePicture')}`,
                    content: `${t('dialog.deletePictureContent')}`,
                }}
            />
        </>
    )
}

RemovedPartTable.propTypes = {
    issueId: PropTypes.string,
    setOpenRemovePart: PropTypes.func,
    techComment: PropTypes.string,
}

RemovedPartTable.defaultProps = {
    issueId: '',
    setOpenRemovePart: () => {},
    techComment: '',
}

export default RemovedPartTable
