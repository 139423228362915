import styled from '@emotion/styled'
import { Box, Grid, LinearProgress } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import Loading from '~/components/Loading'
import Search from '~/components/Search/Search'
import { StyledPaper } from '~/components/StyledPaper'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import { useIssueQuery } from '../query'
import QCFormSection from './QCFormSection'
import { useScanSerialForQcMutation } from './mutation'
import { useIssueQualityCheckQuery } from './query'
import PropTypes from 'prop-types'
import { ISSUE_STATES } from '~/constants/constants'
import { qcFormCriteria } from '~/pages/Issue/QualityCheck/Component/qcFormCriteria'
import { useTranslation } from 'react-i18next'

const Section = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'show',
})(({ show }) => {
    return {
        ...(!show && {
            display: 'none',
        }),
    }
})

const IssueQualityCheck = ({ issueData }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.issues.overview',
    })
    const { issueId: id } = useParams()
    const queryClient = useQueryClient()
    const { data: dataIssue } = useIssueQuery(id)
    const location = useLocation()

    const [error, setError] = useState(false)
    const [isInitialized, setIsInitialized] = useState(true)

    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search],
    )
    const [notHasParamSerials, setNotHasParamSerials] = useState(
        !searchParams.has('serial'),
    )

    const {
        data,
        error: qcCheckError,
        isSuccess: isQcFound,
        isLoading: isFetchingQc,
        isFetched: isFetchedQC,
    } = useIssueQualityCheckQuery(id, notHasParamSerials)

    useEffect(() => {
        if (
            isFetchedQC &&
            isQcFound &&
            isInitialized &&
            dataIssue?.data.state !== 'QC_DONE'
        ) {
            queryClient.invalidateQueries('issue')
            queryClient.invalidateQueries('issue_quality_check')
            setIsInitialized(false)
        }
    }, [isInitialized, dataIssue, isFetchedQC, isQcFound, queryClient])

    const { mutate: scanSerialMutate, isLoading: isScanning } =
        useScanSerialForQcMutation()

    const scanSerial = useCallback(
        (serial) => {
            const defaultAgreeQc = qcFormCriteria
                .filter((criteria) => criteria.leftSide === false)
                .map((criteria) => criteria.name)
            scanSerialMutate(
                { issueId: id, serial, defaultAgreeQc },
                {
                    onSuccess: () => {
                        setError(false)
                    },
                    onError: (e) => {
                        setError(e.response?.data?.message)
                    },
                    onSettled: () => {
                        queryClient.invalidateQueries('issue_quality_check')
                        queryClient.invalidateQueries(['issue_logs'])
                        setNotHasParamSerials(true)
                    },
                },
            )
        },
        [id, queryClient, scanSerialMutate],
    )

    useEffect(() => {
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === dataIssue?.data?.state,
        )
        const conditionToScanSerial =
            stateIndex ===
                ISSUE_STATES.findIndex((state) => state === 'REPAIRED') ||
            stateIndex ===
                ISSUE_STATES.findIndex(
                    (state) => state === 'DIAGNOSED_NO_REPAIR',
                )
        if (
            (qcCheckError?.response?.status === 404 || !notHasParamSerials) &&
            searchParams.has('serial')
        ) {
            if (conditionToScanSerial) {
                scanSerial(searchParams.get('serial'))
            } else {
                setNotHasParamSerials(true)
            }
        }
    }, [scanSerial, notHasParamSerials, searchParams, qcCheckError, dataIssue])

    const handleEnterSearch = (e) => {
        if (e.key === 'Enter') {
            scanSerial(e.target.value)
        }
    }

    if (isFetchingQc) {
        return <Loading></Loading>
    }

    return (
        <>
            {qcCheckError?.response?.status === 404 && (
                <Box>
                    <Search
                        label={t('scan_serial')}
                        autoFocus
                        onKeyPress={handleEnterSearch}
                    />
                    {isScanning && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    )}
                    {error && <ErrorMessageInput>{error}</ErrorMessageInput>}
                </Box>
            )}

            {isQcFound && (
                <>
                    <QCFormSection
                        qcData={data}
                        isFetchedData={isQcFound}
                        issueData={issueData}
                    ></QCFormSection>

                    <Section item xs={12} show={true} sx={{ margin: '10px 0' }}>
                        <StyledPaper variant='outlined'>
                            <IssueLog />
                        </StyledPaper>
                    </Section>

                    <Section item xs={12} show={true}>
                        <StyledPaper variant='outlined'>
                            <DeviceHistory />
                        </StyledPaper>
                    </Section>
                </>
            )}
        </>
    )
}

IssueQualityCheck.propTypes = {
    issueData: PropTypes.object,
}

export default IssueQualityCheck
