import {
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import useRepairPageStyles from './useStyleHook'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useIssuePartCodesQuery } from '../../query'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#d8d8d8',
        border: '0.1px solid grey',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    td: {
        border: '0.1px solid lightgrey',
    },
}))

const PartToChangeTable = ({ issueId, finished, getAll = true }) => {
    const LOCALIZATION = 'pages.issues.repair.'
    const { t } = useTranslation()
    const { classes } = useRepairPageStyles()

    const { data } = useIssuePartCodesQuery(issueId, getAll)

    const rowData = useMemo(() => {
        return data ?? []
    }, [data])

    return (
        <TableContainer className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            {t(`${LOCALIZATION}code`)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t(`${LOCALIZATION}name`)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t(`${LOCALIZATION}type`)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t(`${LOCALIZATION}replacedQuestion`)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t(`${LOCALIZATION}causes`)}
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowData.map((row, index) => {
                        const AD =
                            row.additional_description === null ||
                            row.additional_description.length === 0
                                ? null
                                : t(
                                      `${LOCALIZATION}additionalDescriptionTooltip`,
                                      { AD: row.additional_description },
                                  )
                        return (
                            <Tooltip
                                key={index}
                                arrow
                                title={AD}
                                placement='top-start'
                            >
                                <StyledTableRow key={index}>
                                    <TableCell>{row.code}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    {finished ? (
                                        <TableCell>
                                            {row.removed ? 'Yes' : 'No'}
                                        </TableCell>
                                    ) : (
                                        <TableCell></TableCell>
                                    )}
                                    <TableCell>
                                        {row.causes
                                            .map((x) => x.tag ?? '#')
                                            .join(', ')}
                                    </TableCell>
                                </StyledTableRow>
                            </Tooltip>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

PartToChangeTable.propTypes = {
    issueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    finished: PropTypes.bool,
    getAll: PropTypes.bool,
}

export default PartToChangeTable
