import { CircularProgress, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '~/components/Button'
import PictureCapturePopup from '~/components/PictureCapturePopup'
import { setIsOpen } from '~/store/diagnose/slice'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import ImageList from '~/components/Image/ImageList'
import SectionTitle from './components/SectionTitle'
import {
    ACCESSORY_INCLUDED,
    COSMETIC_DAMAGE,
    LIQUID_DAMAGE,
    LOCALIZATION,
    POWERS_ON,
    SOFTWARE_ISSUE,
} from './constants'
import {
    useDispatchPictureMutation,
    usePictureMutation,
    useRemovePictureMutate,
} from './mutation'
import { usePictureQuery } from './query'
import PropTypes from 'prop-types'
import { useIssueQuery } from '~/pages/Issue/query'
import { ISSUE_STATES } from '~/constants/constants'
import UploadDiagnosticPicturePopup from './UploadDiagnosticPicturePopup'
import { selectUserPermissions } from '~/store/auth/selector'

const PicturesSection = ({
    setDisabledNextButton = null,
    isInPictureStep = true,
    isFinish,
    isTriage = false,
    diagnoseData = {},
    reachOutImageLimitation = false,
    imagesLimitation,
}) => {
    const { t } = useTranslation()
    const userPermissions = useSelector(selectUserPermissions)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isPopupUploadOpen, setIsPopupUploadOpen] = useState(false)
    const { issueId } = useParams()
    const { data, isLoading, refetch } = usePictureQuery(issueId)
    const { data: issueDetail } = useIssueQuery(issueId)
    const { mutate, isLoading: isRemoving } = useRemovePictureMutate(issueId)
    const { mutate: dispatchPictureMutate, isLoading: isUpdating } =
        useDispatchPictureMutation(issueId)
    const { mutate: pictureMutate, isLoading: isPictureLoading } =
        usePictureMutation()
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [openDeleteReject, setOpenDeleteReject] = useState(false)
    const [removePictureId, setRemovePictureId] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([])
    const isHavingUploadPermission =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.diagnose.upload_pictures')

    const isHavingTakePicturePermission =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.diagnose.take_pictures')

    const dispatch = useDispatch()

    const isShownButtons = useMemo(() => {
        if (isFinish) {
            return false
        }
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === issueDetail?.data?.state,
        )

        return !(
            stateIndex >=
                ISSUE_STATES.findIndex((state) => state === 'TRIAGE_DONE') &&
            isTriage
        )
    }, [isFinish, isTriage, issueDetail])

    const onSubmit = useCallback(() => {
        if (selectedFiles.length <= 0) {
            setIsPopupOpen(false)
            setSelectedFiles([])
            dispatch(setIsOpen(true))
            return
        }
        pictureMutate(selectedFiles, {
            onSettled: () => {
                refetch()
                setIsPopupOpen(false)
                setSelectedFiles([])
                dispatch(setIsOpen(true))
            },
        })
    }, [dispatch, pictureMutate, refetch, selectedFiles, setIsPopupOpen])

    const onClose = () => {
        setSelectedFiles([])
        setIsPopupOpen(false)
    }

    const handleRemoveImage = (item) => {
        setRemovePictureId(item.id)
        setOpenDeleteConfirm(true)
    }

    const handleCheckedDispatchPictures = useCallback(
        (checked, image) => {
            if (!isUpdating) {
                // current image
                if (image.is_current) {
                    dispatchPictureMutate([
                        {
                            media_id: image.id,
                            dispatch_enabled: checked,
                        },
                    ])
                } else {
                    // new image
                    setSelectedFiles((files) => {
                        return files.map((file, key) => {
                            if (key === image.id) {
                                file.checked = checked
                            }
                            return file
                        })
                    })
                }
            }
        },
        [dispatchPictureMutate, isUpdating],
    )

    const handleClose = () => {
        setOpenDeleteConfirm(false)
        setOpenDeleteReject(false)
    }
    const handleConfirmRemoveImage = (key) =>
        mutate(key, {
            onSettled: () => {
                refetch()
                setOpenDeleteConfirm(false)
            },
        })

    const currentImageList = useMemo(() => {
        return (
            data?.data &&
            data.data.map((image) => {
                return {
                    src: image.preview_url,
                    id: image.id,
                    is_current: true,
                    checked: image.is_dispatch_image,
                    removeable: image.removeable,
                }
            })
        )
    }, [data])

    useEffect(() => {
        const validationFields = [
            COSMETIC_DAMAGE,
            ACCESSORY_INCLUDED,
            POWERS_ON,
            SOFTWARE_ISSUE,
            LIQUID_DAMAGE,
        ]

        const alreadyChecked = validationFields.some(
            (field) =>
                diagnoseData[field] !== undefined &&
                diagnoseData[field] !== null,
        )

        if (!isInPictureStep || alreadyChecked) {
            return
        }

        if (typeof setDisabledNextButton === 'function') {
            if (data?.data.length > 0) {
                setDisabledNextButton(false)
            } else {
                setDisabledNextButton(true)
            }
        }
    }, [data, isInPictureStep, setDisabledNextButton, diagnoseData])

    return (
        <Grid container spacing={2}>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={12}
                justifyContent='space-between'
            >
                <Grid item>
                    <SectionTitle
                        title={t(LOCALIZATION + `pictures`)}
                        md={!isShownButtons ? 12 : 6}
                        lg={!isShownButtons ? 12 : 6}
                    />
                </Grid>

                {isShownButtons && (
                    <Grid item justifyContent='flex-end'>
                        <>
                            {isHavingTakePicturePermission && (
                                <LoadingButton
                                    label={t(`${LOCALIZATION}addPictures`)}
                                    onClick={() => {
                                        setIsPopupOpen(true)
                                    }}
                                />
                            )}

                            {isHavingUploadPermission && (
                                <LoadingButton
                                    label={t(`${LOCALIZATION}uploadPictures`)}
                                    onClick={() => {
                                        setIsPopupUploadOpen(true)
                                    }}
                                    style={{ marginLeft: '10px' }}
                                />
                            )}
                        </>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} align='center'>
                {!isLoading ? (
                    <ImageList
                        handleRemoveImage={handleRemoveImage}
                        isFinish={isFinish}
                        images={currentImageList}
                        showCheckbox={isShownButtons}
                        showRemoveCurrentImage={isShownButtons}
                        handleChecked={handleCheckedDispatchPictures}
                        reachOutImageLimitation={reachOutImageLimitation}
                        imagesLimitation={imagesLimitation}
                    />
                ) : (
                    <CircularProgress thickness={3} size={20} />
                )}
            </Grid>
            <PictureCapturePopup
                {...{
                    open: isPopupOpen,
                    selectedFiles,
                    setSelectedFiles,
                    isLoading: isPictureLoading,
                    onSubmit,
                    onClose,
                    imageLimit: data?.pictures_limit,
                    btnLabel: 'button.next',
                    currentImageList,
                    handleRemoveCurrent: handleRemoveImage,
                    handleChecked: handleCheckedDispatchPictures,
                    showCheckbox: true,
                }}
            />
            <UploadDiagnosticPicturePopup
                open={isPopupUploadOpen}
                setOpenUploadPicture={setIsPopupUploadOpen}
                refetch={refetch}
            />
            <DeleteConfirmPopup
                {...{ openDeleteConfirm, handleClose }}
                isLoading={isRemoving}
                handleDelete={() => handleConfirmRemoveImage(removePictureId)}
                informationDialog={{
                    title: `${t('dialog.deletePicture')}`,
                    content: `${t('dialog.deletePictureContent')}`,
                }}
            />

            <DeleteConfirmPopup
                {...{ handleClose }}
                openDeleteConfirm={openDeleteReject}
                handleDelete={() => {}}
                informationDialog={{
                    title: `${t('dialog.deletePicture')}`,
                    content: `${t('dialog.deletePictureReject')}`,
                }}
                NoDelete
            />
        </Grid>
    )
}

PicturesSection.propTypes = {
    setDisabledNextButton: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.oneOf([null]),
    ]),
    isInPictureStep: PropTypes.bool,
    isFinish: PropTypes.bool,
    isTriage: PropTypes.bool,
    diagnoseData: PropTypes.object,
    reachOutImageLimitation: PropTypes.bool,
    imagesLimitation: PropTypes.number,
}

PicturesSection.defaultProps = {
    setDisabledNextButton: null,
    isInPictureStep: true,
    isFinish: false,
    isTriage: false,
    diagnoseData: {},
    reachOutImageLimitation: false,
}

export default PicturesSection
